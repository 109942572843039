import React, { useContext } from "react";
import { Nav, Accordion } from "react-bootstrap";
import "./style.scss";
// import logo from "../../../assets/images/logo.svg";
import arrowDown from "../../../assets/images/arrowDown.png";
import { AppContext } from "../../../App";
import { Link, NavLink } from "react-router-dom";

function Sidebar() {
  const { activeMenu } = useContext(AppContext);

  return (
    activeMenu && (
      <div className="main-sidebar">
        {/* <div className="logo-wrap">
          <Nav.Link as={Link} to="/profile">
            <img src={logo} alt="Logo" />
          </Nav.Link>
        </div> */}
         <Nav defaultActiveKey="/profile" className="flex-column">
        <Accordion defaultActiveKey="0">
         
           
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <Nav.Link eventKey="link-1" className="manage">
                  ניהול <img src={arrowDown} alt="arrowDown" />
                  </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <Nav.Link as={NavLink} to="/profile" eventKey="link-2">
                  פרופילים
                  </Nav.Link>
                  {/* <Nav.Link as={NavLink} to="/pricecatalog" eventKey="link-3">
                    Price Catalog
                  </Nav.Link> */}
                  <Nav.Link as={NavLink} to="/city" eventKey="link-4">
                  איזורי שירות
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/category" eventKey="link-5">
                  תחומים
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/post" eventKey="link-6">
                  מאמרים
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/bid" eventKey="link-7">
                  מכרזים
                  </Nav.Link>
                  <Nav.Link as={NavLink} to="/jobsAd" eventKey="link-8">
                 לוחות 
                  </Nav.Link>



                </ul>
              </Accordion.Body>
              </Accordion.Item>
              <Nav.Link
                as={Link}
                className="review"
                eventKey="link-9"
                to="/review"
              >
                 חוות דעת
              </Nav.Link>
              <Nav.Link
                as={Link}
                className="statistic"
                eventKey="link-10"
                to="/statistics"
              >
                סטטיסטיקה
              </Nav.Link>
              <Accordion.Item eventKey="2">
              <Accordion.Header>
                <Nav.Link eventKey="link-11" className="manage message">
                הודעות <img src={arrowDown} alt="arrowDown" />
                </Nav.Link>
              </Accordion.Header>
              <Accordion.Body>
                <ul>
                  <Nav.Link as={Link} className="statistic contact" eventKey="link-12" to="/contact" >
                  יצירת קשר
                  </Nav.Link>
                </ul>
                <ul>
                  <Nav.Link as={Link} className="statistic contact" eventKey="link-13" to="/leads" >
                  הצטרפות
                  </Nav.Link>
                </ul>
              </Accordion.Body> 
              </Accordion.Item>
        </Accordion>
            </Nav>
          
        
      </div>
    )
  );
}
export default Sidebar;
